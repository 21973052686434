import React, {useState} from "react";
import {auth, googleProvider} from "../Firebase/firebase";
import {createUserWithEmailAndPassword, signInWithPopup} from "firebase/auth"
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import logo from "../../img/localtalent-light.svg";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faGoogle} from "@fortawesome/free-brands-svg-icons";
import SignIn from "./SignIn";
import {AbsoluteCenter, Divider} from "@chakra-ui/react";
import {Box} from "@mui/material";

const SignUp = () => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [validated, setValidated] = useState(false);
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const signUp = (e) => {
        e.preventDefault();
        setValidated(true);  // Set validated state before validation check

        const form = e.currentTarget;

        if (!form.checkValidity()) {
            e.stopPropagation();
        } else {
            createUserWithEmailAndPassword(auth, email, password)
                .then((userCredentials) => {
                    console.log(userCredentials);
                })
                .catch((err) => {
                    console.error(err);
                })
        }
    }

    const signInWithGoogle = () => {
        signInWithPopup(auth,googleProvider);
    }

    return(
        <>
            <Button variant="outline-light" size={'sm'} onClick={handleShow}>
                Create Account
            </Button>

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton style={{borderBottom:"none", background:"#212529"}}>
                </Modal.Header>
                <Modal.Title style={{ textAlign: "center", background: "#212529" }}>
                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <img src={logo} alt="Logo" style={{ maxHeight: "50px", width: "auto" }} />
                    </div>
                </Modal.Title>

                <Modal.Body style={{background:"#212529", borderBottomLeftRadius:"6px", borderBottomRightRadius:"6px", padding:"45px 75px 45px"}}>
                    <Form noValidate validated={validated} onSubmit={signUp}>
                        <Button variant="outline-light" size={'lg'} onClick={signInWithGoogle} style={{width: "100%"}}>
                            <FontAwesomeIcon icon={faGoogle} /> Sign In with Google
                        </Button>
                        <Box position='relative' padding='10'>
                            <Divider />
                            <AbsoluteCenter bg='#212529' px='4' color={"white"}>
                                Or
                            </AbsoluteCenter>
                        </Box>
                        <InputGroup className="mb-3">
                            <style type="text/css">
                                {`
                                    #login_email::placeholder, #login_password::placeholder {
                                        color: #888; /* Change this to your desired placeholder color */
                                    }
                                `}
                            </style>
                            <InputGroup.Text style={{background:"#212529", color:"white"}}>Email</InputGroup.Text>
                            <Form.Control
                                style={{background:"#212529", color:"white"}}
                                type={"email"}
                                id={"login_email"}
                                required={true}
                                name="login_email"
                                value={email}
                                onChange={ (e) => setEmail(e.target.value)}
                                placeholder="Email"
                                aria-label="login_email"
                                aria-describedby="basic-addon1"
                            />
                            {validated ?
                                <Form.Control.Feedback type="invalid">
                                    Please provide a valid Email.
                                </Form.Control.Feedback>
                                :
                                ""
                            }
                        </InputGroup>
                        <InputGroup className="mb-3">
                            <InputGroup.Text style={{background:"#212529", color:"white"}}>Password</InputGroup.Text>
                            <Form.Control
                                style={{background:"#212529", color:"white"}}
                                type={"password"}
                                id={"login_password"}
                                required={true}
                                name="login_password"
                                value={password}
                                onChange={ (e) => setPassword(e.target.value)}
                                placeholder="Password"
                                aria-label="login_password"
                                aria-describedby="basic-addon1"
                            />
                            {validated ?
                                <Form.Control.Feedback type="invalid">
                                    Please provide a valid password.
                                </Form.Control.Feedback>
                                :
                                ""
                            }
                        </InputGroup>
                        <div className={"btn-container"} style={{display:"flex", alignItems:"center", justifyContent: "center"}}>
                            <Button variant="outline-light" type="submit" size={"sm"}>Sign Up</Button>
                        </div>
                    </Form>
                    <div className={"btn-container"} style={{color:"white", marginTop:"30px", display:"flex", alignItems:"center", justifyContent: "center"}}>
                        <span style={{marginRight:"10px"}}>Already have an account?</span> <SignIn/>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default SignUp;