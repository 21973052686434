import React, { useState, useEffect } from "react";
import JobDataService from "../../../../services/job/jobService";
import { Col, Row, Card } from "react-bootstrap";
import { useParams} from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faSquareCheck,
    faLocationDot
} from '@fortawesome/free-solid-svg-icons'
import '../../../../css/Divider.css'
import {JobDepartment, JobExpLevel, JobSalary, JobType} from "../../jobs/buildJobItems/JobItems";

function ListingOfJobsOfCompany() {
    const [jobs, setJobs] = useState([]);
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
    const [isLoading, setIsLoading] = useState(false);
    const [idOfJob, setIdOfJob] = useState("");
    const [open, setOpen] = useState(false);
    const [customWidth, setCustomWidth] = useState("75%");
    const {id} = useParams();
    const [emptyArray, setIsEmptyArray] = useState(false);

    // New state to track if job details are shown
    const [showDetails, setShowDetails] = useState({});

    useEffect(() => {
        const retrieveListOfJobsPerCompany = async () => {
            setIsLoading(true);
            try {
                let response = await JobDataService.listingOfJobsOfCompany({ id:id });

                setJobs(response.data.listOfJobsOfCompany);

                if (!Array.isArray(response.data.listOfJobs) || !response.data.listOfJobs.length) {
                    setIsEmptyArray(true);
                } else {
                    setIsEmptyArray(false); // Reset this state if there are jobs
                }

                setIsLoading(false);
            } catch (error) {
                console.error("An error occurred while fetching data: " + error);
            }
        };

        retrieveListOfJobsPerCompany();

        const handleResize = () => {
            setIsMobile(window.innerWidth < 768);
        };

        // Add event listener
        window.addEventListener('resize', handleResize);

        // Call the handler right away so state gets updated with initial window size
        handleResize();

        // Remove event listener on cleanup
        return () => window.removeEventListener('resize', handleResize);

    }, []);

    function calculatePreciseDaysBetweenDates(startDate) {
        const today = new Date();
        const start = new Date(startDate);
        const timeDiff = today - start; // Difference in milliseconds
        const daysDiff = timeDiff / (1000 * 3600 * 24);
        return daysDiff;
    }

    const handleSelectChange = (id) => {
        // Scroll to the top of the page
        window.scrollTo(0, 0);

        if (idOfJob === id) {
            setOpen(!open);
            // Toggle the showDetails state for this job ID
            setShowDetails(prevState => ({...prevState, [id]: !prevState[id]}));
        } else {
            // Handle opening new
            setIdOfJob(id);
            setOpen(true);
            setCustomWidth("95%");
            // Set only the current job's details to true and reset others
            setShowDetails(prevState => {
                const resetState = Object.keys(prevState).reduce((acc, key) => {
                    acc[key] = false; // Reset all to false
                    return acc;
                }, {});
                return {...resetState, [id]: true};
            });
        }
    };


    return (
        jobs.map((jobs) => {
            let josPosted = jobs.job_posted_on;
            const message = calculatePreciseDaysBetweenDates(josPosted) < 1
                ? "less than a day ago"
                : Math.floor(calculatePreciseDaysBetweenDates(josPosted)) + " days ago";

            return (
                <Row key={jobs._id}>
                    <Col md={4} style={{margin: "auto"}}>
                        <Card.Title className={"mb-2"} style={{color: "#cf6a4c"}}>
                            <h3><strong>{jobs.job_title}</strong></h3>
                        </Card.Title>
                        <Card.Subtitle className="mb-2">
                            <h5>
                                {jobs.job_location} <FontAwesomeIcon icon={faLocationDot}/>
                            </h5>
                        </Card.Subtitle>
                    </Col>
                    <Col md={4} style={{margin: "auto"}}>
                        <div style={{
                            textAlign: isMobile ? "start" : "center",
                            padding: isMobile ? "0 0 10px 0" : "10px",
                            paddingTop: "0px"
                        }}>
                            <FontAwesomeIcon icon={faSquareCheck}/> <strong><u>Key Features</u></strong>
                        </div>
                        <Card.Subtitle className="mb-2">
                            <JobDepartment jobs={jobs} />
                            <JobExpLevel jobs={jobs}/>
                            <JobType jobs={jobs}/>
                            <JobSalary jobs={jobs}/>
                        </Card.Subtitle>
                    </Col>
                    <Col md={2} style={{margin: "auto"}}>
                        <Card.Text>
                            <div style={{
                                fontSize: "small",
                                float: isMobile ? "" : "center",
                                marginTop: "10px"
                            }}>
                                Posted {message}
                            </div>
                        </Card.Text>
                    </Col>
                    <hr/>
                </Row>
            );
        })
    )
};
export default ListingOfJobsOfCompany;
