import React, {useEffect, useState} from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import companyDataService from "../../../services/company/companyService";
import logo from "../../../img/localtalent-light.svg";
import { Col, Row } from "react-bootstrap";
import {Alert, AlertDescription, AlertIcon, AlertTitle, useToast} from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom';
import DatePicker from "react-datepicker";
import 'react-datepicker/dist/react-datepicker.css';
import '../../../css/customDatepicker.css'
import '../../../css/ErrorHandling.css'
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

function CompanyForm({ authUser, userEmail }) {

    const [formData, setFormData] = useState({
        company_name: '',
        company_webpage: '',
        company_location: '',
        company_industry:'',
        company_email: '',
        company_description: '',
        company_benefits: '',
        company_logo: '', // Got to do something about this,
        company_size: '',
        company_year_founded: '',
        company_mission_values: '',
        company_key_people: '',
        company_remote_work_policy: '',
        company_user_uid: !authUser ? "" : authUser.uid
    });

    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

    const [notLogged, setNotLogged] = useState(false);

    const toast = useToast()

    const navigate = useNavigate();

    const [errors, setErrors] = useState({});

    function handleChange(event) {
        const { name, value } = event.target;
        setFormData((prevFormData) => ({
            ...prevFormData, [name]: value || '', // Ensure empty values are set correctly
        }));
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        const isFormValid = validateFormData(formData);

        // Check if there are any errors
        if (Object.keys(isFormValid).length > 0) {
            setErrors(isFormValid); // Set the errors to state
            e.stopPropagation();
            return; // Prevents the rest of function from running
        }

        if (notLogged) {
            e.stopPropagation();
        } else {
            const promise = new Promise(async (resolve, reject) => {
                try {
                    await companyDataService.createCompany(formData);

                    resolve();
                    toast({
                        title: 'Redirecting to Company Feed',
                        description: 'In about 5 seconds',
                        status: 'info',
                        duration: 5000,
                        isClosable: true,
                    });
                    setTimeout(() => navigate('/companies'), 5000); // Delay navigation by 5 seconds
                } catch (error) {
                    console.error('Error creating company:', error);
                    reject(error);
                }
            });

            toast.promise(promise, {
                loading: { title: 'Creating company profile...', description: 'Please wait' },
                success: { title: 'Company profile created successfully!', description: 'Operation successful' },
                error: { title: 'Error creating company profile', description: 'Please try again' }
            });
        }
    };

    const handleCompanyFoundingDate = (e) => {
        const { name, value } = e.target ? e.target : e;

        let formattedDate = '';
        if (value instanceof Date) {
            formattedDate = [
                value.getFullYear(),
                ('0' + (value.getMonth() + 1)).slice(-2), // Add leading zero for months 1-9
                ('0' + (value.getDate() + 1)).slice(-2) // Add leading zero for days 1-9
            ].join('-');
        }

        setFormData((prevState) => ({
            ...prevState,
            [name]: formattedDate
        }));
    };

    function validateFormData(formData) {
        const errors = {};

        for (let key in formData) {
            // Skip validation for company_user_uid
            if (key === 'company_user_uid') continue;

            if (!formData[key]) {
                errors[key] = `${key.replace(/_/g, ' ')} is required.`;
            }
        }

        return errors;
    }

    useEffect(() => {
        if (!authUser) {
            setNotLogged(true);
        } else {
            setNotLogged(false);
        }

        const handleResize = () => {
            setIsMobile(window.innerWidth < 768);
        };

        // Add event listener
        window.addEventListener('resize', handleResize);

        // Call the handler right away so state gets updated with initial window size
        handleResize();

        // Remove event listener on cleanup
        return () => window.removeEventListener('resize', handleResize);

    }, [authUser]);

    return (
        <>
            <div style={{width: isMobile ? "90%" : "70%", margin:"auto", padding:"20px"}}>
                <Form noValidate onSubmit={handleSubmit} style={{background: "#212529", padding:"50px", borderRadius:"25px"}}>
                    <div className={"signInLogo"} style={{ textAlign: "center", background: "#212529" }}>
                        <img src={logo} alt="Logo" style={{maxHeight:"50px", width:"auto"}} />
                    </div>
                    <div style={{textAlign:"center", color:"white"}}>
                        <h2><strong>Company Form</strong></h2>
                    </div>
                    {notLogged ?
                        <Row style={{marginBottom:"20px"}}>
                            <Col>
                                <Alert status='error' variant={"left-accent"}>
                                    <AlertIcon />
                                    <AlertTitle>You are not logged in!</AlertTitle>
                                    <AlertDescription>Please login before posting a new company.</AlertDescription>
                                </Alert>
                            </Col>
                        </Row>
                        :
                        ""
                    }
                    <Row>
                        <Col md={4}>
                            <Form.Label style={{background:"#212529", color:"white"}}>Name</Form.Label>
                            <InputGroup className="mb-3">
                                <style type="text/css">
                                    {`
                                        #company_name::placeholder {
                                            color: #888; /* Change this to your desired placeholder color */
                                        }
                                    `}
                                </style>
                                {/*<InputGroup.Text style={{background:"#212529", color:"white"}}>Name</InputGroup.Text>*/}
                                <Form.Control
                                    id={"company_name"}
                                    required={true}
                                    name="company_name"
                                    value={formData.company_name}
                                    onChange={handleChange}
                                    placeholder="LocalTalent"
                                    aria-label="company_name"
                                    aria-describedby="basic-addon1"
                                    style={{background:"#212529", color:"white"}}
                                 />
                            </InputGroup>
                            {errors.company_name && <p className={"field-required"}>Please provide a valid name</p>}
                        </Col>
                        <Col md={4}>
                            <Form.Label style={{background:"#212529", color:"white"}}>Industry</Form.Label>
                            <InputGroup className="mb-3">
                                <style type="text/css">
                                    {`
                                        #company_industry::placeholder {
                                            color: #888; /* Change this to your desired placeholder color */
                                        }
                                    `}
                                </style>
                                {/*<InputGroup.Text style={{background:"#212529", color:"white"}}>Industry</InputGroup.Text>*/}
                                <Form.Control
                                    style={{background:"#212529", color:"white"}}
                                    id={"company_industry"}
                                    required={true}
                                    name="company_industry"
                                    value={formData.company_industry}
                                    onChange={handleChange}
                                    placeholder="Financial Industry"
                                    aria-label="company_industry"
                                    aria-describedby="basic-addon1"
                                />
                            </InputGroup>
                            {errors.company_industry && <p className={"field-required"}>Please provide a valid industry</p>}
                        </Col>
                        <Col md={4}>
                            <Form.Label style={{background:"#212529", color:"white"}}>Founded</Form.Label>
                            <InputGroup className="mb-3">
                                <style type="text/css">
                                    {`
                                        #company_year_founded::placeholder {
                                            color: #888; /* Change this to your desired placeholder color */
                                        }
                                    `}
                                </style>
                                {/*<InputGroup.Text style={{background:"#212529", color:"white"}}>Founded</InputGroup.Text>*/}
                                <DatePicker
                                    id="company_year_founded"
                                    className="form-control"
                                    selected={formData.company_year_founded ? new Date(formData.company_year_founded) : null}
                                    onChange={(date) => handleCompanyFoundingDate({ target: { name: 'company_year_founded', value: date } })}
                                    placeholderText="2020-05-26"
                                    ariaLabelledBy="company_year_founded"
                                    dateFormat="yyyy-MM-dd"
                                    showMonthDropdown
                                    showYearDropdown
                                    dropdownMode="scroll"
                                />
                            </InputGroup>
                            {errors.company_year_founded && <p className={"field-required"}>Please provide a valid founding date</p>}
                        </Col>
                    </Row>
                    <Row>
                        <Col md={4}>
                            <Form.Label style={{background:"#212529", color:"white"}}>Location</Form.Label>
                            <InputGroup className="mb-3">
                                <style type="text/css">
                                    {`
                                        #company_location::placeholder {
                                            color: #888; /* Change this to your desired placeholder color */
                                        }
                                    `}
                                </style>
                                {/*<InputGroup.Text style={{background:"#212529", color:"white"}}>Location</InputGroup.Text>*/}
                                <Form.Control
                                    style={{background:"#212529", color:"white"}}
                                    id={"company_location"}
                                    required={true}
                                    name="company_location"
                                    value={formData.company_location}
                                    onChange={handleChange}
                                    placeholder="San Juan, Puerto Rico"
                                    aria-label="company_location"
                                    aria-describedby="basic-addon1"
                                />
                            </InputGroup>
                            {errors.company_location && <p className={"field-required"}>Please provide a valid location</p>}
                        </Col>
                        <Col md={4}>
                            <Form.Label style={{background:"#212529", color:"white"}}>Website</Form.Label>
                            <InputGroup className="mb-3">
                                <style type="text/css">
                                    {`
                                        #company_webpage::placeholder {
                                            color: #888; /* Change this to your desired placeholder color */
                                        }
                                    `}
                                </style>
                                {/*<InputGroup.Text style={{background:"#212529", color:"white"}}>Website</InputGroup.Text>*/}
                                <Form.Control
                                    style={{background:"#212529", color:"white"}}
                                    id={"company_webpage"}
                                    required={true}
                                    name="company_webpage"
                                    value={formData.company_webpage}
                                    onChange={handleChange}
                                    placeholder="https://www.localtalent.me"
                                    aria-label="company_webpage"
                                    aria-describedby="basic-addon1"
                                />
                            </InputGroup>
                            {errors.company_webpage && <p className={"field-required"}>Please provide a valid website</p>}
                        </Col>
                        <Col md={4}>
                            <Form.Label style={{background:"#212529", color:"white"}}>Email</Form.Label>
                            <InputGroup className="mb-3">
                                <style type="text/css">
                                    {`
                                        #company_email::placeholder {
                                            color: #888; /* Change this to your desired placeholder color */
                                        }
                                    `}
                                </style>
                                {/*<InputGroup.Text style={{background:"#212529", color:"white"}}>Email</InputGroup.Text>*/}
                                <Form.Control
                                    style={{background:"#212529", color:"white"}}
                                    id={"company_email"}
                                    type="email"
                                    required={true}
                                    name="company_email"
                                    value={formData.company_email}
                                    onChange={handleChange}
                                    placeholder="support@localtalent.com"
                                    aria-label="company_email"
                                    aria-describedby="basic-addon1"
                                />
                            </InputGroup>
                            {errors.company_email && <p className={"field-required"}>Please provide a valid email</p>}
                        </Col>
                    </Row>
                    <Row>
                        <Col md={4}>
                            <Form.Label style={{background:"#212529", color:"white"}}>Size</Form.Label>
                            <InputGroup className="mb-3">
                                <style type="text/css">
                                    {`
                                        #company_size::placeholder {
                                            color: #888; /* Change this to your desired placeholder color */
                                        }
                                    `}
                                </style>
                                {/*<InputGroup.Text style={{background:"#212529", color:"white"}}>Size</InputGroup.Text>*/}
                                <Form.Select
                                    style={{background:"#212529", color:"white"}}
                                    id={"company_size"}
                                    required={true}
                                    name="company_size"
                                    value={formData.company_size}
                                    onChange={handleChange}
                                    aria-label="company_size"
                                    aria-describedby="basic-addon1"
                                >
                                    <option>--Select Number of Employees--</option>
                                    <option key={"1"} value={"1"}>1 - 10 employees</option>
                                    <option key={"2"} value={"2"}>11 - 50 employees</option>
                                    <option key={"3"} value={"3"}>51 - 100 employees</option>
                                    <option key={"4"} value={"4"}>100+ employees</option>
                                </Form.Select>
                            </InputGroup>
                            {errors.company_size && <p className={"field-required"}>Please select a valid company size</p>}
                        </Col>
                        <Col md={4}>
                            <Form.Label style={{background:"#212529", color:"white"}}>Remote Work Policy</Form.Label>
                            <InputGroup className="mb-3">
                                <style type="text/css">
                                    {`
                                        #company_remote_work_policy::placeholder {
                                            color: #888; /* Change this to your desired placeholder color */
                                        }
                                    `}
                                </style>
                                {/*<InputGroup.Text style={{background:"#212529", color:"white"}}>Remote Work Policy</InputGroup.Text>*/}
                                <Form.Select
                                    style={{background:"#212529", color:"white"}}
                                    id={"company_remote_work_policy"}
                                    required={true}
                                    name="company_remote_work_policy"
                                    value={formData.company_remote_work_policy}
                                    onChange={handleChange}
                                    aria-label="company_remote_work_policy"
                                    aria-describedby="basic-addon1"
                                >
                                    <option>--Select Remote Work Policy--</option>
                                    <option key={"remote"} value={"remote"}>Fully Remote</option>
                                    <option key={"hybrid"} value={"hybrid"}>Hybrid (Remote & On-Site)</option>
                                    <option key={"on-site"} value={"on-site"}>On-Site Only</option>
                                </Form.Select>
                            </InputGroup>
                            {errors.company_remote_work_policy && <p className={"field-required"}>Please select a valid remote work policy</p>}
                        </Col>
                        <Col md={4}>
                            <Form.Label style={{background:"#212529", color:"white"}}>Key People</Form.Label>
                            <InputGroup className="mb-3">
                                <style type="text/css">
                                    {`
                                        #company_key_people::placeholder {
                                            color: #888; /* Change this to your desired placeholder color */
                                        }
                                    `}
                                </style>
                                {/*<InputGroup.Text style={{background:"#212529", color:"white"}}>Key People</InputGroup.Text>*/}
                                <Form.Control
                                    style={{background:"#212529", color:"white"}}
                                    id={"company_key_people"}
                                    required={true}
                                    name="company_key_people"
                                    value={formData.company_key_people}
                                    onChange={handleChange}
                                    placeholder="John Doe - CEO"
                                    aria-label="company_key_people"
                                    aria-describedby="basic-addon1"
                                />
                            </InputGroup>
                            {errors.company_key_people && <p className={"field-required"}>Please provide a valid key person</p>}
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                            <Form.Label style={{background:"#212529", color:"white"}}>Mission & Values</Form.Label>
                            <InputGroup className="mb-3">
                                <div style={{ width: '100%' }}>
                                    {/*<InputGroup.Text style={{background:"#212529", color:"white"}}>Mission & Values</InputGroup.Text>*/}
                                    <CKEditor
                                        editor={ClassicEditor}
                                        data={formData.company_mission_values}
                                        onChange={(event, editor) => {
                                            const content = editor.getData();
                                            handleChange({ target: { name: 'company_mission_values', value: content }});
                                        }}
                                        config={{
                                            placeholder: "Type in the mission & values for this company...",
                                            toolbar: [ 'undo', 'redo', '|', 'heading', 'bold', 'italic', '|', 'numberedList', 'bulletedList' ],
                                        }}
                                    />
                                </div>
                            </InputGroup>
                            {errors.company_mission_values && <p className={"field-required"}>Please provide a valid mission & values</p>}
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6}>
                            <Form.Label style={{background:"#212529", color:"white"}}>Description</Form.Label>
                            <InputGroup className="mb-3">
                                <div style={{ width: '100%' }}>
                                    {/*<InputGroup.Text style={{background:"#212529", color:"white"}}>Description</InputGroup.Text>*/}
                                    <CKEditor
                                        editor={ClassicEditor}
                                        data={formData.company_description}
                                        onChange={(event, editor) => {
                                            const content = editor.getData();
                                            handleChange({ target: { name: 'company_description', value: content }});
                                        }}
                                        config={{
                                            placeholder: "Type in the description for this company...",
                                            toolbar: [ 'undo', 'redo', '|', 'heading', 'bold', 'italic', '|', 'numberedList', 'bulletedList' ],
                                        }}
                                    />
                                </div>
                            </InputGroup>
                            {errors.company_description && <p className={"field-required"}>Please provide a valid description</p>}
                        </Col>
                        <Col md={6}>
                            <Form.Label style={{background:"#212529", color:"white"}}>Benefits</Form.Label>
                            <InputGroup className="mb-3">
                                <div style={{ width: '100%' }}>
                                    {/*<InputGroup.Text style={{background:"#212529", color:"white"}}>Benefits</InputGroup.Text>*/}
                                    <CKEditor
                                        editor={ClassicEditor}
                                        data={formData.company_benefits}
                                        onChange={(event, editor) => {
                                            const content = editor.getData();
                                            handleChange({ target: { name: 'company_benefits', value: content }});
                                        }}
                                        config={{
                                            placeholder: "Type in the benefits for this company...",
                                            toolbar: [ 'undo', 'redo', '|', 'heading', 'bold', 'italic', '|', 'numberedList', 'bulletedList' ],
                                        }}
                                    />
                                </div>
                            </InputGroup>
                            {errors.company_benefits && <p className={"field-required"}>Please provide valid benefits</p>}
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                            <Form.Label style={{background:"#212529", color:"white"}}>Logo</Form.Label>
                            <InputGroup className="mb-3">
                                <style type="text/css">
                                    {`
                                        #company_logo::placeholder {
                                            color: #888; /* Change this to your desired placeholder color */
                                        }
                                    `}
                                </style>
                                {/*<InputGroup.Text style={{background:"#212529", color:"white"}}>Logo</InputGroup.Text>*/}
                                <Form.Control
                                    style={{background:"#212529", color:"white"}}
                                    id={"company_logo"}
                                    required={true}
                                    name="company_logo"
                                    value={formData.company_logo}
                                    onChange={handleChange}
                                    placeholder="this will be changed later"
                                    aria-label="company_logo"
                                    aria-describedby="basic-addon1"
                                />
                            </InputGroup>
                            {errors.company_logo && <p className={"field-required"}>Please provide a valid company logo</p>}
                        </Col>
                    </Row>
                    <div className={"btn-container"} style={{display:"flex", alignItems:"center", justifyContent: "center", padding:"20px"}}>
                        <Button variant="outline-light"  type="submit" style={{textAlign:"center"}}>
                            Submit
                        </Button>
                    </div>
                </Form>
            </div>
        </>
    );
}

export default CompanyForm;
