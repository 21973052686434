import React, { useState, useEffect } from "react";
import CompanyDataService from "../../../../services/company/companyService";
import ListingOfJobsOfCompany from "./ListingOfJobsOfCompany";
import { Col, Row, Container, Card, Button } from "react-bootstrap";
import { useParams, Link } from 'react-router-dom'
import Spinner from 'react-bootstrap/Spinner';
import DOMPurify from 'dompurify';
import {CompanyCard, DescriptionCard, BenefitsCard} from "./CompanyDetails";
import HandleJoinRequest from "./RequestToJoinCompany";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock } from '@fortawesome/free-solid-svg-icons';
import LoadingScreenIndividual from "../../../Basic/LoadingScreenIndividual";

function IndividualCompanyProfile() {
    const [companies, setCompanies] = useState([]);
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
    const [isLoading, setIsLoading] = useState(false);
    const {id} = useParams();

    useEffect(() => {
        retrieveCompanies();

        const handleResize = () => {
            setIsMobile(window.innerWidth < 768);
        };

        // Add event listener
        window.addEventListener('resize', handleResize);

        // Call the handler right away so state gets updated with initial window size
        handleResize();

        // Remove event listener on cleanup
        return () => window.removeEventListener('resize', handleResize);

    }, []);

    const retrieveCompanies = async () => {
        setIsLoading(true);
        try {
            let response = await CompanyDataService.fetchCompanyById(id);
            setCompanies(response.data.company);
            setIsLoading(false);
        } catch (error) {
            console.error("An error occurred while fetching data: " + error);
        }
    };

    const sanitizedHtmlCompDesc = DOMPurify.sanitize(companies.company_description); //sanitize HTML content especially when using dangerouslySetInnerHTML, to prevent potential cross-site scripting (XSS) attacks
    const sanitizedHtmlCompBen = DOMPurify.sanitize(companies.company_benefits); //sanitize HTML content especially when using dangerouslySetInnerHTML, to prevent potential cross-site scripting (XSS) attacks
    return (
        <div className="App">
            <Container>
                {isLoading ? (
                    <LoadingScreenIndividual individual={"individualCompany"} />
                ) : (
                    <>
                        <Row>
                            <Col>
                                <Row style={{ padding: "20px" }}>
                                    <CompanyCard companies={companies} isMobile={isMobile}/>
                                </Row>
                                <Row style={{ padding: "20px" }}>
                                    <HandleJoinRequest companies={companies} userInfo={"Luis"} uid={""}/>
                                </Row>
                            </Col>


                            <Col>
                                <Row style={{ padding: "20px" }}>
                                    <Card style={{ margin: 'auto', borderRadius: "15px", color:"white" }} bg={"dark"}>
                                        <Card.Body style={{color: "white", textAlign: isMobile ? "start" : "justify" }}>
                                            <Col>
                                                <Card.Title style={{textAlign: "center"}}>
                                                    <h2><FontAwesomeIcon icon={faClock} /> <b>Latest 2 Jobs</b></h2>
                                                </Card.Title>
                                                <hr/>
                                                <ListingOfJobsOfCompany/>
                                                <Button size={"sm"} as={Link} to={"/listing/company/" + companies._id} variant={"outline-light"} style={{ background:"#cc5500" }}><b>Available Job Listings</b></Button>
                                            </Col>
                                        </Card.Body>
                                    </Card>
                                </Row>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Row style={{padding: "20px"}}>
                                    <DescriptionCard companies={companies} sanitizedHtmlCompDesc={sanitizedHtmlCompDesc} />
                                </Row>
                            </Col>
                            <Col>
                                <Row style={{padding: "20px"}}>
                                    <BenefitsCard companies={companies} sanitizedHtmlCompBen={sanitizedHtmlCompBen} />
                                </Row>
                            </Col>
                        </Row>
                    </>
                )}
            </Container>
        </div>
    );
};
export default IndividualCompanyProfile;
