import Popover from "react-bootstrap/Popover";
import Button from "react-bootstrap/Button";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faClock, faDollarSign, faUser} from "@fortawesome/free-solid-svg-icons";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import React from "react";

export const JobExpLevel = ({ jobs }) => {
    let expLevel = "";
    switch (jobs.job_exp_level) {
        case "intern":
            expLevel = "Internship";
            break;
        case "jr":
            expLevel = "Jr. Level";
            break;
        case "mid":
            expLevel = "Mid Level";
            break;
        case "senior":
            expLevel = "Senior Level";
            break;
        default:
            expLevel = "non-existing";
            break;
    }

    return (
        <OverlayTrigger
            trigger="hover"
            key={"job_exp_level"}
            placement={"auto"}
            overlay={
                <Popover id={`popover-positioned-right`}>
                    <Popover.Header as="h3">{`Role Experience`}</Popover.Header>
                    <Popover.Body>
                        <span>
                            Position seeks candidates with <b>{expLevel}</b> professional experience.
                        </span>
                    </Popover.Body>
                </Popover>
            }
        >
            <Button variant="outline-light" style={{margin: '2px', background: "#767b91"}}>
                <FontAwesomeIcon icon={faUser}/> <strong>{expLevel}</strong>
            </Button>
        </OverlayTrigger>
    )
}

export const JobDepartment = ({ jobs }) => {
    return (
        <OverlayTrigger
            trigger="hover"
            key={"job_exp_level"}
            placement={"auto"}
            overlay={
                <Popover id={`popover-positioned-right`}>
                    <Popover.Header as="h3">{`Role Experience`}</Popover.Header>
                    <Popover.Body>
                        <span>
                            Position seeks candidates with <b>{jobs.job_department}</b> professional experience.
                        </span>
                    </Popover.Body>
                </Popover>
            }
        >
            <Button variant="outline-light" style={{margin: '2px', background: "#4a5759"}}>
                <FontAwesomeIcon icon={faUser}/> <strong>{jobs.job_department}</strong>
            </Button>
        </OverlayTrigger>
    )
}

export const JobType = ({ jobs }) => {
    let type = "";
    switch (jobs.job_type) {
        case "part":
            type = "Part-Time";
            break;
        case "full":
            type = "Full-Time";
            break;
        default:
            type = "";
            break;
    }

    return (
        <OverlayTrigger
            trigger="hover"
            key={"job_type"}
            placement={"auto"}
            overlay={
                <Popover id={`popover-positioned-right`}>
                    <Popover.Header as="h3">{`Type`}</Popover.Header>
                    <Popover.Body>
                        <span>
                            Position is a <b>{type}</b>  employment opportunity.
                        </span>
                    </Popover.Body>
                </Popover>
            }
        >
            <Button variant="outline-light" style={{ margin: '2px', background:"#403d39"}}>
                <FontAwesomeIcon icon={faClock} /> <strong>{type}</strong>
            </Button>
        </OverlayTrigger>
    )
}

export const JobSalary = ({ jobs }) => {
    return (
        <OverlayTrigger
            trigger="hover"
            key={"job_salary_range"}
            placement={"auto"}
            overlay={
                <Popover id={`popover-positioned-right`}>
                    <Popover.Header as="h3">{`Compensation`}</Popover.Header>
                    <Popover.Body>
                        <span>
                            {jobs.job_salary_range == "" ? "Compensation details are not available for this post." : "Position has a compensation of around " + jobs.job_salary_range + " US Dollars."}
                        </span>
                    </Popover.Body>
                </Popover>
            }
        >
            <Button variant="outline-light" style={{ margin: '2px', background:"#bb9457" }}>
                <FontAwesomeIcon icon={faDollarSign} /> <strong>{jobs.job_salary_range == "" ? "N/A" : jobs.job_salary_range}</strong>
            </Button>
        </OverlayTrigger>
    )
}