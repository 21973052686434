import axios from "axios";

class jobDataService {
    async getAllJobs(page = 0) {
        try {
            const response = await axios.get(`https://localtalent-backend.onrender.com/jobs/all?page=${page}`);
            return response;
        } catch (error) {
            console.error("An error occurred while fetching data: " + error);
        }
    }

    async fetchJobById(id) {
        try {
            const response = await axios.get(`https://localtalent-backend.onrender.com/jobs/job/${id}`);
            return response;
        } catch (error) {
            console.error("An error occurred while fetching data: " + error);
        }
    }

    async fetchUserJobs(uid, currentPage) {
        try {
            const response = await axios.post(`https://localtalent-backend.onrender.com/jobs/my-jobs`, uid, currentPage);
            return response;
        } catch (error) {
            console.error("An error occurred while fetching data: " + error);
        }
    }

    async listOfJobsPerCompany(id) {
        try {
            const response = await axios.get(`https://localtalent-backend.onrender.com/jobs/listing/company/${id}`);
            return response;
        } catch (error) {
            console.error("An error occurred while fetching data: " + error);
        }
    }

    async listingOfJobsOfCompany(id) {
        try {
            const response = await axios.post(`https://localtalent-backend.onrender.com/jobs/listing-individual-company`, id);
            return response;
        } catch (error) {
            console.error("An error occurred while fetching data: " + error);
        }
    }

    async createJob(form) {
        try {
            await axios.post(`https://localtalent-backend.onrender.com/jobs/new/job`, form);
        } catch (error) {
            console.error("An error occurred while fetching data: " + error);
        }
    }

    async updateJob(id, form) {
        try {
            await axios.put(`https://localtalent-backend.onrender.com/jobs/update/job/${id}`, form);
        } catch (error) {
            console.error("An error occurred while fetching data: " + error);
        }
    }

    async deleteJob(id) {
        try {
            await axios.delete(`https://localtalent-backend.onrender.com/jobs/delete/job/${id}`);
        } catch (error) {
            console.error("An error occurred while fetching data: " + error);
        }
    }
}

export default new jobDataService();