import React, { useState, useEffect } from "react";
import CompanyDataService from "../../../services/company/companyService";
import { Col, Row, Container, Card } from "react-bootstrap";
import Button from 'react-bootstrap/Button';
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faEnvelope,
    faBuilding,
    faGlobe,
    faLocationDot,
    faIndustry, faCaretRight
} from '@fortawesome/free-solid-svg-icons'
import {Alert, AlertIcon, AlertTitle} from '@chakra-ui/react'
import Popover from "react-bootstrap/Popover";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import '../../../css/BasicComponentList.css';
import LoadingScreenFeed from "../../Basic/LoadingScreenFeed";

function CompanyProfileList() {
    const [companies, setCompanies] = useState([]);
    const [currentPage, setCurrentPage] = useState(0);
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
    const [isLoading, setIsLoading] = useState(false);
    const [emptyArray, setIsEmptyArray] = useState(false);

    useEffect(() => {
        retrieveCompaniesInfo();

        const handleResize = () => {
            setIsMobile(window.innerWidth < 768);
        };

        // Add event listener
        window.addEventListener('resize', handleResize);

        // Call the handler right away so state gets updated with initial window size
        handleResize();

        // Remove event listener on cleanup
        return () => window.removeEventListener('resize', handleResize);

    }, [currentPage]);

    const retrieveCompaniesInfo = async () => {
        setIsLoading(true);
        try {
            let response = await CompanyDataService.getAllCompanies(currentPage);
            setCompanies(response.data.companies);
            setCurrentPage(response.data.page);

            if (!Array.isArray(response.data.companies) || !response.data.companies.length) {
                setIsEmptyArray(true);
            } else {
                setIsEmptyArray(false); // Reset this state if there are jobs
            }

            setIsLoading(false);
        } catch (error) {
            console.error("An error occurred while fetching data: " + error);
        }
    };

    return (
        <div className="App">
            <Container>
                {isLoading ? (
                    <LoadingScreenFeed feed={"company"}/>
                    )
                    : (
                        emptyArray ?
                            <Row>
                                <Col>
                                    <Alert status='info'
                                           flexDirection='column'
                                           alignItems='center'
                                           justifyContent='center'
                                           textAlign='center'>
                                        <AlertIcon boxSize='40px' mr={0} />
                                        <AlertTitle mt={4} mb={1} fontSize='lg'>
                                            Results Info
                                        </AlertTitle>
                                        No results found.
                                    </Alert>
                                </Col>
                            </Row>
                            :
                            <Row>
                                <Col>
                                    {companies.map((companies) => {
                                        return (
                                            <Row key={companies._id}>
                                                <Card style={{ width: "85%"}} bg={"dark"} className={'basic-component-list'}>
                                                    <Card.Body style={{color: "white", textAlign: "start"}}>
                                                        <Row>
                                                            <Col md={4} style={{margin:"auto"}}>
                                                                <Row>
                                                                    <Card.Title>
                                                                        <OverlayTrigger
                                                                            trigger="hover"
                                                                            key={"companyDetails_company"}
                                                                            placement={"auto"}
                                                                            overlay={
                                                                                <Popover id={`popover-positioned-right`}>
                                                                                    <Popover.Header as="h3">{`Company Profile`}</Popover.Header>
                                                                                    <Popover.Body>
                                                                            <span>
                                                                                Redirect me to <b>{companies.company_name}</b> profile page.
                                                                            </span>
                                                                                    </Popover.Body>
                                                                                </Popover>
                                                                            }
                                                                        >
                                                                            <h3>
                                                                                <Link to={"/company/" + companies._id}>
                                                                                    <FontAwesomeIcon icon={faBuilding} /> <b>{companies.company_name}</b>
                                                                                </Link>
                                                                            </h3>
                                                                        </OverlayTrigger>
                                                                    </Card.Title>
                                                                </Row>
                                                                <Row>
                                                                    <Card.Subtitle className="mb-3">
                                                                        <div>
                                                                            <FontAwesomeIcon icon={faGlobe} /> <a href={companies.company_webpage}>{companies.company_webpage}</a>
                                                                        </div>
                                                                    </Card.Subtitle>
                                                                </Row>
                                                                <Row>
                                                                    <Card.Subtitle className="mb-3">
                                                                        <div>
                                                                            EST. {companies.company_year_founded}
                                                                        </div>
                                                                    </Card.Subtitle>
                                                                </Row>
                                                            </Col>
                                                            <Col md={4} style={{margin:"auto", textAlign:"start"}}>
                                                                <Row>
                                                                    <Card.Subtitle className="mb-3">
                                                                    <span>
                                                                        <FontAwesomeIcon icon={faIndustry} /> Industry <span style={{color:"#bbb193"}}><u>{companies.company_industry}</u></span>
                                                                    </span>
                                                                    </Card.Subtitle>
                                                                </Row>
                                                                <Row>
                                                                    <Card.Subtitle className="mb-3">
                                                                <span>
                                                                    <FontAwesomeIcon icon={faLocationDot} /> Based on <span style={{color:"#c2948a"}}><u>{companies.company_location}</u></span>
                                                                </span>
                                                                    </Card.Subtitle>
                                                                </Row>
                                                                <Row>
                                                                    <Card.Subtitle className="mb-3">
                                                            <span>
                                                                <FontAwesomeIcon icon={faEnvelope} /> Email <span style={{color:"#7ea8be"}}>{companies.company_email}</span>
                                                            </span>
                                                                    </Card.Subtitle>
                                                                </Row>
                                                            </Col>
                                                            { isMobile ? "" : <div className="divider"></div>}
                                                            <Col md={2} style={{margin:"auto"}}>
                                                                <Row>
                                                                    <Card.Text>
                                                                        <Button as={Link} to={"/listing/company/" + companies._id} variant={"outline-light"} style={{background:"#cc5500"}}><strong>Job Listings <FontAwesomeIcon icon={faCaretRight} /></strong></Button>
                                                                    </ Card.Text>
                                                                </Row>
                                                            </Col>
                                                        </Row>
                                                    </Card.Body>
                                                </Card>
                                            </Row>
                                        );
                                    })}
                                </Col>
                            </Row>
                    )}
            </Container>
        </div>
    );
};
export default CompanyProfileList;
