// Cards.js
import React from "react";
import { Col, Row, Card } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faEnvelope,
    faBuilding,
    faGlobe,
    faLocationDot,
    faIndustry,
    faPenToSquare,
    faPaperclip
} from '@fortawesome/free-solid-svg-icons';

export const CompanyCard = ({ companies, isMobile }) => {
    return (
        companies && (
            <Card key={companies._id} style={{ margin: "auto", borderRadius: "15px"}} bg={"dark"}>
                <Card.Body style={{color: "white", textAlign: isMobile ? "center" : "justify"}}>
                    <Col>
                        <Row style={{padding: "20px"}}>
                            <Card.Title>
                                <h2>
                                    <FontAwesomeIcon icon={faBuilding} /> <b>{companies.company_name}</b>
                                </h2>
                            </Card.Title>
                            <Row>
                                <Col>
                                    <Card.Subtitle className="mb-3">
                                        <div>
                                            <FontAwesomeIcon icon={faGlobe}/> {companies.company_webpage}
                                        </div>
                                    </Card.Subtitle>
                                </Col>
                                <Col>
                                    <Card.Subtitle className="mb-3">
                                        <span>
                                            <FontAwesomeIcon icon={faIndustry} /> Industry <span style={{color:"#bbb193"}}><u>{companies.company_industry}</u></span>
                                        </span>
                                    </Card.Subtitle>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Card.Subtitle className="mb-3">
                                        <span>
                                            <FontAwesomeIcon icon={faLocationDot} /> Based on <span style={{color:"#c2948a"}}><u>{companies.company_location}</u></span>
                                        </span>
                                    </Card.Subtitle>
                                </Col>
                                <Col>
                                    <Card.Subtitle className="mb-3">
                                        <span>
                                            <FontAwesomeIcon icon={faEnvelope} /> Email <span style={{color:"#7ea8be"}}>{companies.company_email}</span>
                                        </span>
                                    </Card.Subtitle>
                                </Col>
                            </Row>
                        </Row>
                    </Col>
                </Card.Body>
            </Card>
        )
    );
};

export const DescriptionCard = ({ companies, sanitizedHtmlCompDesc, isMobile }) => {
    return (
        companies && (
            <Card key={companies._id} style={{ margin: "auto", borderRadius: "15px" }} bg={"dark"}>
                <Card.Body style={{color: "white", textAlign: "center"}}>
                    <Card.Title>
                        <h2><FontAwesomeIcon icon={faPenToSquare}/> <b>Description</b></h2>
                    </Card.Title>
                    <hr/>
                    <Card.Text>
                        <div style={{
                            borderRadius: "10px",
                            padding: "20px",
                            color: "white",
                            textAlign: "justify"
                        }}>
                            <div dangerouslySetInnerHTML={{__html: sanitizedHtmlCompDesc}}/>
                        </div>
                    </Card.Text>
                </Card.Body>
            </Card>
        )
    );
};

export const BenefitsCard = ({companies, sanitizedHtmlCompBen, isMobile}) => {
    return (
        companies && (
            <Card key={companies._id} style={{ margin: "auto", borderRadius: "15px" }} bg={"dark"}>
                <Card.Body style={{color: "white", textAlign: "center"}}>
                    <Card.Title>
                        <h2><FontAwesomeIcon icon={faPaperclip}/> <b>Benefits</b></h2>
                    </Card.Title>
                    <hr/>
                    <Card.Text>
                        <div style={{
                            borderRadius: "10px",
                            padding: "20px",
                            color: "white",
                            textAlign: "justify"
                        }}>
                            <div dangerouslySetInnerHTML={{__html: sanitizedHtmlCompBen}}/>
                        </div>
                    </Card.Text>
                </Card.Body>
            </Card>
        )
    );
};