import CompanyDataService from "../../../../services/company/companyService";
import React, { useState } from "react";
import {Button, Card, Col, Row} from "react-bootstrap";
import Collapse from "react-bootstrap/Collapse";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faFaceSmile,
    faUserPlus
} from '@fortawesome/free-solid-svg-icons'

const HandleJoinRequest = ({ companies, userInfo, uid }) => {
    const [showConfirmation, setShowConfirmation] = useState(false);

    const handleJoinRequest = async () => {
        const requesterName = "John Doe"; // This should come from the logged-in user's profile
        const requesterUid = "user-uid-from-firebase"; // This should come from Firebase Auth

        try {
            let response = await CompanyDataService.requestJoiningCompanies();
            alert('Join request sent successfully!');
        } catch (error) {
            console.error('Error sending join request:', error);
            alert('Failed to send join request.');
        }
        setShowConfirmation(false);
    };

    return (
        <Card key={companies._id} style={{ margin: "auto", borderRadius: "15px", color:"white"}} bg={"dark"}>
            <Card.Body>
                <Card.Title>
                    <h2>
                        <FontAwesomeIcon icon={faUserPlus} /> Work for this company?
                    </h2>
                </Card.Title>
                <Card.Text>
                    <div>
                        If you are an employee of this company but have not yet joined the company group,
                        please click the button below to request access <FontAwesomeIcon icon={faFaceSmile} />
                    </div>
                </Card.Text>
                <Card.Text>
                    <Button size={"sm"} variant={"outline-light"} style={{ background: "#cc5500" }} onClick={() => setShowConfirmation(true)}><b>Request to join?</b></Button>
                </Card.Text>
                <Collapse in={showConfirmation}>
                    <div className="confirmation-dialog">
                        <p>Please confirm that you wish to request access to join the <b>{companies.company_name}</b> company group.</p>
                        <Row sm={6} className="justify-content-center">
                            <Col>
                                <Button variant="outline-light" size={"sm"} onClick={handleJoinRequest}>Confirm</Button>
                            </Col>
                            <Col>
                                <Button variant="outline-danger" size={"sm"} onClick={() => setShowConfirmation(false)}>Cancel</Button>
                            </Col>
                        </Row>
                    </div>
                </Collapse>
            </Card.Body>
        </Card>
    );
};

export default HandleJoinRequest;