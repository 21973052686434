import './App.css';
import "bootstrap/dist/css/bootstrap.min.css"
import React, { useState, useEffect, useContext } from "react";
import { useLocation } from "react-router-dom";

// Firebase Authentication
import FirebaseContext from "./component/Firebase/context";

// Custom Components
import Footer from "./component/Basic/Footer";
import NavBar from "./component/Basic/NavBar";
import {HOME, JOBS_FEED, COMPANIES_FEED, MY_JOBS,} from "./constants/routes"
import CustomRoutes from "./component/Basic/Routes";

// Custom Pagination
import JobsPagination from "./component/Pagination/JobsPagination";
import CompanyPaginationItem from "./component/Pagination/CompanyPagination";

function App() {
    const { currentUser } = useContext(FirebaseContext);
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
    const [currentJobPage, setCurrentJobPage] = useState(0);
    const [currentCompanyPage, setCurrentCompanyPage] = useState(0);
    const location = useLocation();

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 768);
        };

        // Add event listener
        window.addEventListener('resize', handleResize);

        // Call the handler right away so state gets updated with initial window size
        handleResize();

        // Remove event listener on cleanup
        return () => {
            window.removeEventListener('resize', handleResize); // This removes the resize event listener
        };
    }, []);

    return (
        <div className="page-container">
            <div className="content-wrap">
                <NavBar authUser={currentUser} isMobile={isMobile}/>
                <CustomRoutes authUser={currentUser} currentJobPage={currentJobPage}/>
            </div>
            {(location.pathname === HOME || location.pathname === JOBS_FEED || location.pathname === MY_JOBS) && (
                <>
                    <JobsPagination currentPage={currentJobPage} setCurrentPage={setCurrentJobPage} />
                </>
            )}

            {(location.pathname === COMPANIES_FEED) && (
                <>
                    <CompanyPaginationItem currentPage={currentCompanyPage} setCurrentPage={setCurrentCompanyPage} />
                </>
            )}
            <Footer />
        </div>
    );
}

export default App;
