import React, { useState, useEffect } from 'react';
import FirebaseContext from './context';
import { auth, googleProvider } from './firebase';

const FirebaseProvider = ({ children }) => {
    const [currentUser, setCurrentUser] = useState(null);

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged(user => {
            setCurrentUser(user);
        });

        // Cleanup subscription on unmount
        return () => unsubscribe();
    }, []);

    return (
        <FirebaseContext.Provider value={{ auth, googleProvider, currentUser }}>
            {children}
        </FirebaseContext.Provider>
    );
};

export default FirebaseProvider;
