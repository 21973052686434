import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import {
    COMPANIES_FEED,
    HOME,
    JOBS_FEED,
    NEW_COMPANY,
    NEW_JOB,
    MY_JOBS
} from "../../constants/routes";
import {Col, NavDropdown, Row} from "react-bootstrap";
import SignUp from "../Auth/SignUp";
import SignIn from "../Auth/SignIn";
import React, { useContext } from "react";
import {signOut} from "firebase/auth";
import FirebaseContext from "../Firebase/context";
import { Divider} from '@chakra-ui/react'
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faPlus,
    faBriefcase,
    faBuilding,
    faArrowRightFromBracket,
    faCircleInfo
} from '@fortawesome/free-solid-svg-icons'
import { ReactComponent as Logo } from '../../img/localtalent-light.svg'; // Adjust the path as necessary

function NavBar({ authUser, isMobile }) {
    const {auth, googleProvider} =  useContext(FirebaseContext)
    const userSignOut = () => {
        const confirmSignOut = window.confirm("Are you sure you want to sign out?");
        if (confirmSignOut) {
            signOut(auth)
                .then(() => console.log("Signed out successfully."))
                .catch((err) => console.error(err));
        }
    }

    const renderNavItems = () => (
        <>
            <Nav.Item>
                <Nav.Link as={Link} to={JOBS_FEED}><FontAwesomeIcon icon={faBriefcase} /> Jobs</Nav.Link>
            </Nav.Item>
            <Nav.Item>
                <Nav.Link as={Link} to={COMPANIES_FEED}><FontAwesomeIcon icon={faBuilding} /> Companies</Nav.Link>
            </Nav.Item>
        </>
    );

    const renderUserSection = () => {
        if (authUser) {
            return (
                isMobile ?
                    <Row style={{fontSize:"small", textAlign:"center"}}>
                        <Col>
                            <Nav.Item>
                                <Nav.Link as={Link} to={MY_JOBS}>My Job Posts</Nav.Link>
                            </Nav.Item>
                        </Col>
                        <Col>
                            <Nav.Item>
                                <Nav.Link as={Link}>My Company</Nav.Link>
                            </Nav.Item>
                        </Col>
                        <Col>
                            <Nav.Item>
                                <Nav.Link as={Link}>My Account</Nav.Link>
                            </Nav.Item>
                        </Col>
                        <Col>
                            <Nav.Item eventKey="logout">
                                <Nav.Link as={Link} onClick={userSignOut}>Logout</Nav.Link>
                            </Nav.Item>
                        </Col>
                    </Row>
                    :
                    <NavDropdown
                        id={ isMobile ? "" : "nav-dropdown-user-auth"}
                        title={authUser.displayName === "" ? authUser.email : authUser.displayName}
                        menuVariant="dark"
                        style={{color:"white", textAlign:"center"}}
                        drop={isMobile ? "end" : "start"}
                    >
                        <Nav.Item eventKey={"myJobs"}>
                            <Link to={MY_JOBS} className="dropdown-item"><FontAwesomeIcon icon={faBriefcase} /> My Job Posts</Link>
                        </Nav.Item>
                        <Nav.Item eventKey="myCompany">
                            <Link to={"#"} className="dropdown-item"><FontAwesomeIcon icon={faBuilding} /> My Company</Link>
                        </Nav.Item>
                        <NavDropdown.Divider style={{background:"white"}}/>
                        <Nav.Item eventKey="myAccount">
                            <Link to={"#"} className="dropdown-item"><FontAwesomeIcon icon={faCircleInfo} /> My Account</Link>
                        </Nav.Item>
                        <NavDropdown.Divider style={{background:"white"}}/>
                        <Nav.Item eventKey="logout">
                            <Link onClick={userSignOut} className="dropdown-item"><FontAwesomeIcon icon={faArrowRightFromBracket} /> Logout</Link>
                        </Nav.Item>
                    </NavDropdown>
            );
        }
        return (
            <div style={{textAlign:"end"}}>
                <SignIn />
                <SignUp />
            </div>
        );
    };

    return (
        <Navbar expand="lg" variant="dark" bg="dark" style={{margin: "0 0 5% 0"}}>
            <Container fluid>
                <Navbar.Brand as={Link} to={HOME}>
                    <Logo alt="Logo" width={70} height={30} />
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="navbarScroll" />
                <Navbar.Collapse id="navbarScroll">
                    <Nav variant={"underline"} className={`me-auto my-2 my-lg-0 ${isMobile ? "" : "navbar-underline"}`} fill>
                        {renderNavItems()}
                        <NavDropdown
                            id="nav-dropdown-dark-example"
                            title={<span><FontAwesomeIcon icon={faPlus} /> POST</span>}
                            menuVariant="dark"
                        >
                            <Link to={NEW_JOB} className="dropdown-item"><FontAwesomeIcon icon={faPlus} /> Add Job</Link>
                            <Link to={NEW_COMPANY} className="dropdown-item"><FontAwesomeIcon icon={faPlus} /> Add Company</Link>
                        </NavDropdown>
                        {isMobile ? <Divider /> : ""}
                        { isMobile ? renderUserSection() : ""}
                    </Nav>
                    { isMobile ? "" : renderUserSection()}
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
}

export default NavBar;